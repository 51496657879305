import React, { useState, useEffect, useLayoutEffect } from "react";
import { Endpoints } from "../../repository/Endpoints";
import { Base64RequestMaker, PostMaker } from "../../repository/RequestMaker";
import { Spinner } from "react-bootstrap";
import { saveAs } from "file-saver";
import Logout from "../../utils/sharedComponents/Logout";
import demo from "../../utils/demo.pdf";
import { useHistory } from "react-router-dom";

const Certificate = () => {
  //inits
  const [isLoading, setIsLoading] = useState(false);
  const [pdfFile, setPdfFile] = useState("");
  const enrollment_id = localStorage.getItem("enrollment_id");
  const tenant_id = localStorage.getItem("tenant_id");
  const [cc, setCc] = useState("");
  const profile_id = localStorage.getItem("profile_id");
  const history = useHistory();

  //logics
  const b64toBlob = (b64Data, sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: "application/pdf" });
    return blob;
  };

  const onNext = (e) => {
    e.preventDefault();
    const username = localStorage.getItem("username");
    const usercompany = localStorage.getItem("usercompany");
    const kindaUnique = Date.now();
    saveAs(pdfFile, username + "_" + usercompany + "_" + kindaUnique + ".pdf");
  };
  useEffect(() => {
    window.scrollTo(0, 0);

    PostMaker(
      {
        enrollment_id: enrollment_id,
        tenant_id: tenant_id,
        cc: "",
        profile_id: profile_id,
      },
      Endpoints.certificate
    ).then((res) => {
      const blob = b64toBlob(res);
      const blobUrl = URL.createObjectURL(blob);
      setPdfFile(blobUrl);
      setIsLoading(false);
    });
  }, []);

  useLayoutEffect(() => {
    // setTimeout(() => {
    //   var iframe = document.getElementById("pdf-js-viewer");
    //   const pdftoolbar =
    //     iframe.contentWindow.document.getElementsByClassName("toolbar")[0];
    //   pdftoolbar.style.display = "none";
    //   const pdffileInput =
    //     iframe.contentWindow.document.getElementById("fileInput");
    //   pdffileInput.style.display = "none";
    // }, 1000);
  }, []);

  const sendCertificateCC = () => {
    if (cc !== "") {
      PostMaker(
        {
          enrollment_id: enrollment_id,
          tenant_id: tenant_id,
          cc: cc,
          profile_id: profile_id,
        },
        Endpoints.certificate
      ).then((res) => {
        alert("Zertifikat geliefert an " + cc);
      });
    } else {
      alert("Email form empty");
    }
  };
  return (
    <>
      <div className="bg-mine" style={{}}>
        {isLoading ? (
          <Spinner animation="grow" />
        ) : (
          <>
            <div>
              <h4 className="display-5" id="title_text">
                Herzlichen Glückwunsch
              </h4>
              <p id="subtitle_text">
                Vielen Dank für Ihre Teilnahme an der Einweisung.
              </p>
              <p id="subtitle_text">
                Das Zertifikat wird auch an Ihre E-Mail Adresse gesendet.
              </p>
              <div className="d-flex" style={{justifyContent:'space-between'}}>
                <button className="btn-round" onClick={onNext}>
                  <i class="fa fa-download" aria-hidden="true"></i>
                </button>
                <button
                  className="btn-smol"
                  style={{ marginLeft: 10, alignSelf:'flex-end', backgroundColor:'red' }}
                  onClick={() => {
                    history.push({
                      pathname: "/home",
                    });
                  }}
                >
                  <i class="fa fa-power-off" aria-hidden="true"></i>
                </button>
              </div>
              <hr className="my-4" />
            </div>
            <div style={{ height: "55vh" }}>
              <iframe
                id="pdf-js-viewer"
                src={"lib/web/viewer.html?file=" + pdfFile}
                title="webviewer"
                frameBorder="0"
                width="100%"
                height="100%"
                style={{ borderRadius: "20px" }}
              ></iframe>
            </div>
          </>
        )}
      </div>
      <Logout />
    </>
  );
};

export default Certificate;
