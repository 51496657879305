import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { GlobalProvider } from "./context/GlobalState";
import Home from "./pages/home/Home";
import Userdata from "./pages/userdata/Userdata";
import Courses from "./pages/courses/Courses";
import Training from "./pages/training/Training";
import Quiz from "./pages/quiz/Quiz";
import TestingUI from "./pages/testingUI/TestingUI";
import background from "./utils/back.jpg";
import Certificate from "./pages/certificate/Certificate";
import MaterialRouter from "./repository/MaterialRouter";
import TrainingPDF from "./pages/training/TrainingPDF";
import Logo from "./utils/sharedComponents/Logo";
import PrivacyStatement from "./pages/privacyStatement/PrivacyStatement";
import Imprint from "./pages/privacyStatement/Imprint";
import useScreenOrientation from "react-hook-screen-orientation";
import logo from './utils/logo.png'

function App() {
  const screenOrientation = useScreenOrientation();
  return (
    <>
      {screenOrientation === "landscape-primary" ? (
        <Logo />
      ) : (
        <nav
          className="navbar d-flex justify-content-center"
          style={{ padding: 10, backgroundColor:'white', marginBottom:-70 }}
        >
          <img
            src={logo}
            height="70"
          />
        </nav>
      )}

      <div className="container">
        <Router>
          <Route exact path="/" component={Home} />
          <Route exact path="/home" component={Home} />
          <Route exact path="/userdata" component={Userdata} />
          <Route exact path="/courses" component={Courses} />
          <Route exact path="/training" component={Training} />
          <Route exact path="/trainingpdf" component={TrainingPDF} />
          <Route exact path="/quiz" component={Quiz} />
          <Route exact path="/certificate" component={Certificate} />
          <Route exact path="/material" component={MaterialRouter} />
          <Route exact path="/privacyStatement" component={PrivacyStatement} />
          <Route exact path="/imprint" component={Imprint} />
          <Route exact path="/tui" component={TestingUI} />
        </Router>
      </div>
    </>
  );
}

export default App;
